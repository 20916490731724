<template>
  <div>
    <div
      v-if="canAccessEbook"
      :class="!isNoteOpen && 'container-xl' "
      :style="{ maxWidth: !isNoteOpen ? '1140px' : ''}"
    >
      <div
        v-if="canShowNotes"
        class="floating d-flex flex-column align-items-end"
      >
        <div class="mb-1">
          <div
            v-if="selectedClassRoom"
            v-b-tooltip.hover.v-info
            class="btn d-flex align-items-center justify-content-center text-white btn-icon rounded-circle shadow toggle-button"
            title="Show Notes"
            @click="isNoteOpen = !isNoteOpen"
          >
            <feather-icon
              style="height: 18px; width: 18px"
              icon="ClipboardIcon"
            />
          </div>
          <div
            v-b-tooltip.hover.v-info
            class="btn d-flex align-items-center justify-content-center text-white btn-icon rounded-circle shadow toggle-button-download mt-2"
            title="Export To PDF"
            @click="exportPdf"
          >
            <feather-icon
              style="height: 18px; width: 18px"
              icon="DownloadCloudIcon"
            />
          </div>
          <div
            v-if="selectedClassRoom"
            v-b-tooltip.hover.v-info
            title="Show Class Script"
            class="btn d-flex align-items-center justify-content-center text-white btn-icon rounded-circle shadow script-button mt-2"
            @click="showClassRoomScript"
          >
            <feather-icon
              style="height: 18px; width: 18px"
              icon="FileTextIcon"
            />
          </div>
          <div
            v-if="selectedClassRoom"
            v-b-tooltip.hover.v-info
            title="Show Scoreboard"
            class="btn d-flex align-items-center justify-content-center text-white btn-icon rounded-circle shadow scoreboard-button mt-2"
            @click="openScoreBoard"
          >
            <feather-icon
              style="height: 18px; width: 18px"
              icon="UsersIcon"
            />
          </div>
          <div
            v-if="isATeacher"
            v-b-tooltip.hover.v-info
            title="Show Lesson Planner"
            class="btn d-flex align-items-center bg-success justify-content-center text-white btn-icon rounded-circle shadow scoreboard-button mt-2"
            @click="()=>{$bvModal.show('lessonPlannerViewModal')}"
          >
            <feather-icon
              style="height: 18px; width: 18px"
              icon="BookIcon"
            />

          </div>
        </div>
      </div>
      <b-overlay
        id="overlay-background"
        :show="isProcessing"
        :opacity="1"
        rounded="sm"
      >
        <div
          v-if="curriculumData!=null"
          :class="curriculumData.color_scheme"
          class="curriculum"
        >
          <div
            class="row"
            :style="{ background: isNoteOpen ? '#f8f8f8' : ''}"
          >
            <div :class="`col${isNoteOpen || showClassScript ? '-8 pl-2' : ''}`">
              <component
                :is="layoutName"
                :key="(curriculumData?.page_id || pageId)"
                :page="currentPage"
                :curriculum="curriculumData"
                :page-id="pageId"
                :results="problemResults"
                :questions="paragraphQuestions[currentPage]"
                :variant="variant"
                :is-note-open="isNoteOpen"
              >
                <template #ebook-selector>
                  <div v-if="lessonId && curriculumData?.page_no"
                       class="mr-1"
                  >
                    <OtherVersionSelector
                      :key="curriculumData?.page_no +'_'+ lessonId"
                      :lesson-id="Number(lessonId)"
                      :page-no="Number(curriculumData?.page_no)"
                      :version="Number(curriculumData.lang_level)"
                    />
                  </div>
                </template>
              </component>

              <footer
                v-show="curriculumData!=null"
                class="mt-1 d-flex justify-content-center p-2"
              >
                <Pagination :pagination="{
                              total: pageIds.length + 1,
                              per_page: 1,
                              current_page: currentPage + 1,
                              last_page: pageIds.length,
                            }"
                            :custom-page-slot="page => page == 6 ? $t('essay-builder-module.essay') : page"
                            @onPaginationChange="index => onPageChange(index - 1)"
                />
              </footer>
            </div>
            <div
              v-if="isNoteOpen"
              class="col-4 position-fixed right-section"
            >
              <lesson-notes
                v-if="isNoteOpen != 'class-script' && isNoteOpen != 'lesson-planner'"
                :lesson-id="Number(lessonId)"
                @close="isNoteOpen = false"
              />
              <ClassRoomScript
                v-if="isNoteOpen == 'class-script' "
                :show="showClassScript"
                :with-modal="false"
                class="mt-3"
                :class-id="parseInt(selectedClassRoom)"
                :is-high-level-user="false"
                @close="()=>{showClassScript = false; isNoteOpen=null}"
              />
            </div>
          </div>

        </div>
      </b-overlay>
    </div>
    <div v-else>
      <div class="text-center mt-5">
        <div class="mb-3">
          <feather-icon
            size="64"
            icon="LockIcon"
            class="text-danger"
          />
        </div>
        <h2 class="text-danger mb-1">
          Access Restricted
        </h2>
        <p class="text-muted">
          {{ $t('error-restricted') }}
        </p>
        <b-button
          variant="primary"
          class="mt-2"
          tag="a"
          href="/"
        >
          {{ $t('return-home') }}
        </b-button>
      </div>
    </div>
    <div
      class="position-absolute"
      style="bottom: 0;"
    >
      <!-- Issue Report -->
      <issue-report
        v-if="isATeacher"
        :lesson-id="lessonId"
        default-issue-title="Curriculum page Issue"
        class="issue-report"
        :user-id="self.id"
      />
    </div>
    <YoutubeVideoPlayer v-if="youtubeVideoLink"
                        :youtube-video-link="youtubeVideoLink"
                        @close="youtubeVideoLink = null"
    />
    <lesson-planner-view
      :lesson-id="lessonId"
      :event-id="selectedEventId"
      class="mt-3"
    />
  </div>
</template>

<script>
import {
  BButton,
  BOverlay,
  BFormSelect,
  VBTooltip,
  BModal,
  BCard,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import TranslationSection from '@/views/common/lessons/components/TranslationSection.vue'
import Layout6 from '@views/student/essay/Index.vue'
import { getLanguageLevel, getColorForLevel } from '@/const/langLevel'
import IssueReport from '@/views/student/problem/compoments/IssueReport.vue'
import html2pdf from 'html2pdf.js'
import ClassRoomScript from '@/views/v2/common/class/components/class-script/index.vue'
import LessonPlannerView from '@/views/teacher/class/lesson-planner/View.vue'
import { validatorYoutubeUrlValidator } from '@/@core/utils/validations/validators'
import Pagination from '@/views/common/components/Pagination.vue'
import EventBus from '@/utils/eventBus'
import Layout1 from './layouts/Layout1.vue'
import Layout2 from './layouts/Layout2.vue'
import Layout3 from './layouts/Layout3.vue'
import Layout4 from './layouts/Layout4.vue'
import Layout5 from './layouts/Layout5.vue'
import LessonNotes from '../../lessons/Notes/index.vue'
import OtherVersionSelector from './OtherVersionSelector.vue'
import VocabCardWithLanguage from './layouts/components/VocabCardWithLanguage.vue'
import YoutubeVideoPLayer from './Tools/YoutubeVideoPlayer.vue'

export default {
  components: {
    Pagination, Layout1, YoutubeVideoPLayer, Layout2, Layout3, Layout4, Layout5, BButton, BOverlay, BFormSelect, TranslationSection, Layout6, LessonNotes, IssueReport, ClassRoomScript, OtherVersionSelector, BModal, LessonPlannerView, BCard, VocabCardWithLanguage,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: this.show,
      curriculumData: null,
      currentPage: null,
      isProcessing: false,
      pageId: null,
      activeWord: '',
      activeTranslation: {},
      activeLanguage: null,
      activeWordNets: {},
      activeOffset: null,
      pageIds: [],
      words: [],
      startedAt: (new Date()).getTime(),
      problemResults: {},
      paragraphQuestions: {},
      variant: 'variant-2',
      isNoteOpen: false,
      self: this.AUTH_USER() || {},
      selectedClassRoom: this.$route.query.classId,
      selectedEventId: this.$route.query.eventId,
      showClassScript: false,
      ebooks: [],
      youtubeVideoLink: null,
      openLessonPlanner: false,
      imageLibraryData: {},
      isLoading: false,
      canAccessEbook: true,
    }
  },
  computed: {
    layoutName() {
      const layout = this.currentPage + 1
      return `Layout${layout}`
    },
    activeLanguageShort() {
      if (this.activeLanguage === 'Korean') {
        return 'kor'
      }

      return 'jpn'
    },
    lessonId() {
      return this.curriculumData?.lesson_id
    },
    canShowNotes() {
      return !this.isNoteOpen && (this.pageId || this.pageIds?.length) && this.lessonId
    },
    languageLevel() {
      if (!this.curriculumData?.version) return null
      const version = getLanguageLevel(this.curriculumData.version)
      return {
        version,
        color: getColorForLevel(version),
      }
    },
    isATeacher() {
      return this.self.usertype === 'teacher'
    },
    classId() {
      return this.$route.query.classId
    },
  },

  watch: {
    show() {
      this.isVisible = this.show
    },
    pageId() {
      if (this.pageId) {
        this.fetchPageData()
      }
    },
  },
  mounted() {
    document.addEventListener('click', e => {
      if (e.target.className === 'selectable-answer') {
        this.checkSolution(e.target.dataset.questionIndex, e.target.dataset.setIndex, e.target.dataset.problemId, e.target.innerText)
      }
      if (e.target.classList.contains('youtube-video-button')) {
        const link = e.target.getAttribute('link')
        this.youtubeVideoLink = validatorYoutubeUrlValidator(link)
      }
    })
  },
  created() {
    this.initializeData()
    EventBus.$off('refetchPageData')
    EventBus.$on('refetchPageData', this.initializeData)
  },
  methods: {
    initializeData() {
      this.pageId = this.$route.params.pageId
      this.fetchPageData()
      this.getWordNets()
    },
    generateProblems() {
      this.curriculumData.sets.forEach((e, index) => {
        const data = {
          statement: e.paragraph.question ? e.paragraph.question : e.paragraph.content,
          problemId: e.paragraph.problem_id,
          ...(e.paragraph.question ? this.getQuestionFromParagraph(index, e.paragraph.problem_id, e.paragraph.question) : { html: e.paragraph.content, id: e.paragraph.id }),
        }
        this.paragraphQuestions[this.currentPage][index] = data
      })
      this.paragraphQuestions = JSON.parse(JSON.stringify(this.paragraphQuestions))
    },
    regenerateProblems(setIndex) {
      const question = this.paragraphQuestions[this.currentPage][setIndex]
      this.paragraphQuestions[this.currentPage][setIndex].html = this.getQuestionFromParagraph(setIndex, question.problemId, question.statement).html
    },
    getQuestionFromParagraph(setIndex, id, question) {
      const statements = question.split('***')
      let html = ''
      const eachQuestion = {}
      statements.forEach((e, wordIndex) => {
        const trimmedE = e.trim()
        const ind = question.indexOf(trimmedE)
        const before = question.substring(ind - 3, ind)
        const after = question.substring(ind + trimmedE.length, ind + trimmedE.length + 3)

        if (before === '***' && after === '***') {
          let className = ''
          const paragraphQuestion = this.paragraphQuestions[this.currentPage][setIndex] && this.paragraphQuestions[this.currentPage][setIndex].questions[wordIndex]
          if (paragraphQuestion?.isAnswered) {
            className = paragraphQuestion.isCorrect ? 'correct-answer' : 'wrong-answer'
          } else {
            className = 'selectable-answer'
          }
          html += `<span data-question-index="${wordIndex}" data-set-index="${setIndex}" data-problem-id="${id}" class="${className}">${e}</span>`
          eachQuestion[wordIndex] = {
            isAnswered: false,
            isCorrect: false,
          }
        } else html += e
      })
      return {
        questions: eachQuestion,
        html,
      }
    },
    checkSolution(questionIndex, setIndex, id, answer) {
      const timeSpent = parseInt((((new Date()).getTime() - this.startedAt) / 1000), 10)
      useJwt
        .checkTextSelectionSolution({
          answer, body_time: timeSpent, st_time: 0, time_spent: timeSpent, problem_id: id,
        })
        .then(response => {
          this.paragraphQuestions[this.currentPage][setIndex].questions[questionIndex].isCorrect = response.data.data
          this.paragraphQuestions[this.currentPage][setIndex].questions[questionIndex].isAnswered = true
          this.regenerateProblems(setIndex, questionIndex)
        })
    },
    onPageChange(index) {
      this.pageId = this.pageIds[index]
      this.currentPage = index
      if (this.pageId) {
        this.$router.replace({ name: this.$route.name, params: { ...this.$route.params, pageId: this.pageId } })
      }
    },
    previousPage() {
      if (!this.currentPage > 0) return
      this.isProcessing = true
      setTimeout(() => {
        this.currentPage -= 1
        this.pageId = this.pageIds[this.currentPage]
        if (this.pageId) {
          this.$router.replace({ name: this.$route.name, params: { ...this.$route.params, pageId: this.pageId } })
        }
      }, 100)
    },
    nextPage() {
      if (this.currentPage === 5) return
      if (this.currentPage > 5) return
      setTimeout(() => {
        this.currentPage += 1
        this.pageId = this.pageIds[this.currentPage]
        // this.fetchPageData()
        if (this.pageId) {
          this.$router.replace({ name: this.$route.name, params: { ...this.$route.params, pageId: this.pageId }, query: { ...this.$route.query } })
        }
      }, 100)
    },
    openScoreBoard() {
      window.open(`/student-role/${this.selectedClassRoom}`, '_blank', 'toolbar=no,scrollbars=yes,resizable=yes,top=500,left=500,width=625,height=400')
    },
    fetchPageData() {
      if (!this.pageId) return
      this.isProcessing = true
      useJwt.getCurriculumPage(this.pageId, { params: { student_lab: true, classId: this.classId } }).then(res => {
        this.curriculumData = res.data.data
        this.pageIds = this.curriculumData.page_ids
        this.currentPage = this.pageIds.indexOf(this.curriculumData.page_id)
        // generate questions from paragraph
        if (!this.paragraphQuestions[this.currentPage]) {
          this.paragraphQuestions[this.currentPage] = []
        }
        this.generateProblems()
      }).finally(() => {
        this.isProcessing = false
      }).catch(error => {
        console.log({ error })
        if (error.response?.data?.data === 403) {
          this.canAccessEbook = false
        }
        this.showErrorMessage(error)
      })
    },
    openTranslationModal(word) {
      this.isLoading = true
      this.activeWord = word
      this.activeWordNets = {}

      useJwt.getWordNet({
        page_id: this.pageId,
        word,
      }).then(res => {
        if (res.status === 200) {
          this.activeWordNets = res.data.data
          const selectedWordnets = []
          this.activeWordNets.wordnet.forEach(w => {
            if (w.selected === 1) {
              selectedWordnets.push(w)
            }
          })
          if (selectedWordnets.length > 0) {
            this.activeWordNets.wordnet = selectedWordnets
          }
          const filteredWordNet = this.activeWordNets.wordnet.find(w => w.selected === 1) ? this.activeWordNets.wordnet.find(w => w.selected === 1) : this.activeWordNets.wordnet[0]
          const { offset } = filteredWordNet
          const translatedWord = filteredWordNet[this.activeLanguageShort]
          this.activeOffset = offset
          if (translatedWord) {
            this.activeTranslation.words = [translatedWord]
          } else {
            this.getTranslation(offset)
          }
        }
      }).finally(() => { this.isLoading = false })
    },
    getWordNets() {
      if (!this.pageId) return
      useJwt.getWordNetsForPage(this.pageId).then(res => {
        Object.values(res.data.data).forEach(word => {
          this.words.push(word.word)
        })
      })
    },
    getTranslation(offset) {
      useJwt.getTranslatedWords({
        word_offset: offset,
        language: this.activeLanguage,
      }).then(res => {
        if (res.status === 200) {
          this.activeTranslation = JSON.parse(res.data.data)
        }
      })
    },
    setActiveLanguage(language) {
      this.openTranslationModal(this.activeWord)
      this.activeLanguage = language
    },
    showClassRoomScript() {
      this.isNoteOpen = 'class-script'
      this.showClassScript = true
    },
    async getBase64Image(url) {
      // Convert to base64
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.onload = function () {
          const reader = new FileReader()
          reader.onloadend = function () {
            resolve(reader.result)
          }
          reader.readAsDataURL(xhr.response)
        }
        xhr.onerror = () => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({
            status: this.status,
            statusText: xhr.statusText,
          })
        }
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.send()
      })
    },
    exportPdf() {
      const opt = {
        pagespilt: true,
        filename: `curriculum_ebook_${this.lessonId}.pdf`,
        image: { type: 'jpeg', quality: 0.20 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'p' },
      }

      html2pdf().set(opt).from(document.getElementById('overlay-background')).save()
    },
    getImageLibrary() {
      this.isLoading = true
      useJwt.getImageLibrary({
        params: {
          term: this.activeWord,
        },
      }).then(response => {
        const { data } = response.data.data

        this.imageLibraryData = data.find(vocab => vocab.lang === 'english')
        const otherVersions = data.filter(vocab => vocab.lang !== 'english')
        this.imageLibraryData.other_version = otherVersions
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>
<style lang="scss">
  @import "/src/assets/scss/custom/lesson-themes.scss";
  .variant-2 {
    height:auto;
  }
  .content-left {
    float: none !important;
    width: 100% !important;
  }
  .content-left p{
    color: black;
  }
  .modal .modal-custom {
    max-width: 1400px;
    width: 1400px;
  }
  .question-box p {
    font-style: italic;
  }
  .selectable-answer {
    transition: all 0.2s ease-in;
  }

  .variant-2 .question-para,
  .variant-2 .question-box p {
    font-style: normal;
  }
  .variant-2 .selectable-answer:hover {
    text-decoration: none;
  }
  .selectable-answer{
    cursor: pointer;
    /* color: #3498db !important; */
    text-decoration: underline;
    transition: all 200ms ease-in-out;
  }
  .correct-answer{
    color: #27ae60 !important;
  }
  .wrong-answer{
    color: #c0392b !important;
  }
  // .wordnet_popup{
  //   font-style:italic;
  //   font-weight:500;
  // }
  .question-para{
    font-size:16px !important;
    font-style: italic;
    font-weight:550;
  }
  .variants {
    width: 120px;
    position: absolute;
    right: 150px;
    top: 10px;
    z-index: 1;
  }
  .floating {
    position: fixed;
    top: 18%;
    right: 2%;
    z-index: 1000;
  }
  .has-notes{
    background-color: #e67e22 !important;
  }
  .toggle-button {
    height: 50px;
    background-color: #e74c3c;
    width: 50px;
    border: 2px solid white !important;
  }
  .script-button {
    height: 50px;
    background-color: #762323;
    width: 50px;
    border: 2px solid white !important;
  }
  .scoreboard-button {
    height: 50px;
    background-color: #1f27a0;
    width: 50px;
    border: 2px solid white !important;
  }
  .toggle-button-download {
    height: 50px;
    background-color: #237636;
    width: 50px;
    border: 2px solid white !important;
  }
  .close-button {
    height: 22px;
    width: 22px;
    position: absolute;
    right: -12px;
    top: -12px;
  }

  .version-label {
    position: absolute;
    right: 0;
    margin-right: 20px;
    font-size: 16px;
  }
  .right-section{
    overflow: auto;
    height:100vh;
    right:0;
    padding-bottom: 20px;
    overscroll-behavior: contain;
  }
  .ebook-text {
    display: flex;
    justify-content: center; /* centers horizontally */
    align-items: center;
  }
  .review-section {
    display:flex;
    align-items: center
  }
</style>
